import React, { useState, useEffect, useContext } from 'react';
import marked from '../utils/markedRenderer';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import * as Scroll from 'react-scroll';

import { Text } from '@latitude/text';
import { FeaturesSlider } from '@latitude/features-slider';
import { PLEligibilityCriteria } from '@/components/PersonalLoanPageContent/PersonalLoanPageContent';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import HowToApply from '@latitude/how-to-apply';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import CalcData from '../data/pages/loans/loan-repayment-calculator.json';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import Faq from '@/components/Faq/Faq';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import LoanRates from '@/components/LoanRates/LoanRates';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/debt-consolidation-loans.json';
import PLData from '@/data/pages/personal-loan.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { Box } from '@/components/Box/Box';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  ALIGN,
  LINE_HEIGHT,
  SITE_URL,
  SOFT_QUOTE_LOANS_AU_URL
} from '@/utils/constants';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { graphql, useStaticQuery } from 'gatsby';
import { Heading4 } from '@latitude/heading';
import { CardGroup, CardGroupItem } from '../components/CardGroup/CardGroup';
import ArticleTile from '../templates/life-done-better/ArticleTile';
import LoanProcessList from '@/components/LoanProcessList/LoanProcessList';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import footerData from '@/data/json/footer.json';
import heroImage from '../images/hero/debt-consolidation-hero-image.webp';
import styled from 'styled-components';
import SvgInline from '@latitude/svg-inline';
import Button from '../components/Button/Button';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

const DebtConsolidationLoanPage = ({ location }) => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: PageData.content.features.title,
      featureCards: PageData.content.features.whyDebtConsolidation
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'debt-consolidation'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  const pageQuery = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            glob: "**/src/templates/life-done-better/articles/*.md"
          }
          frontmatter: {
            title: {
              in: [
                "Debt Consolidation: What You Need to Know"
                "Debt Consolidation: What You Should Consider"
                "Is a Debt Consolidation Loan Right for You?"
              ]
            }
          }
        }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              path
              thumbnail
            }
          }
        }
      }
    }
  `);
  return (
    <Layout location={location} customFooter={footerData} hasStickyNav>
      <main className="navigation-spacer navigation-spacer--hasStickyNav">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          <Helmet>
            <link rel="canonical" href={`${SITE_URL}${location.pathname}`} />
            <title>{PageData.metaTitle}</title>
            <meta name="description" content={PageData.metaDesc} />
          </Helmet>
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              title={
                <>
                  Debt Consolidation
                  <br />
                  Loans
                </>
              }
              subTitle={
                <>
                  Keep things simple by combining multiple repayments into one.
                  Apply for a Latitude Debt Consolidation Loan online in 7
                  minutes
                  <span className="conditional-text">
                    {' '}
                    and get a response to your application in only 60 seconds.
                  </span>
                </>
              }
              pageImage={heroImage}
              pageImageAlt="debt consolidation loan"
            >
              <div className="text-white row">
                <div className="text-left col-6 col-md-5">
                  <HomeButton
                    href={applyUrl}
                    trackId="debt-consolidation-loan-button"
                    trackEventData={{
                      label: 'Get Rate Estimate',
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLAULF-WEB']}
                    className="button--primary"
                  >
                    Get Rate Estimate
                  </HomeButton>
                </div>
                <div className="p-0 text-center col-1">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="text-left col-5 col-md-6 HeroContent__insettext">
                  Check your interest rate in 2 minutes with no impact to your
                  credit score.
                </div>
              </div>
            </PageHeaderSection>
          )}

          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:2;"
          >
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber={PLData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaText="Get Rate Estimate"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
              offsetElem="[data-sticky-navigation-offset]"
              {...state?.inPageNavData?.[0]}
            />
          </div>
          <EstimateRateWidgetSection
            purpose="debt-consolidation"
            parentOnChangeEvent={() => {
              setSoftQuoteHref(redirectUrl());
            }}
          />
          <LoanProcessList
            title="How the debt consolidation <br /> process works"
            data={PageData.content.loanProcess.list}
            id="how-it-works"
          />
          <LoanRates
            isBranded
            rateBoxType="variableSym"
            rateBoxType2="fixedSym"
            fees={PLData.content.aboutTheLoan}
            extraTCs="true"
            determineRateLink
          />
          <FeesAndCharges data={PLData.content.feesAndCharges} />
          <FeatureTiles
            id="calculators-and-tools"
            heading={CalcData.content.calculatorsTitle}
            tiles={CalcData.content.calculators}
          />
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="why-debt-con"
            className="bg-white why-choose"
            data={(
              state?.featureSliderData?.[0] || featureSliderData[0]
            ).featureCards?.map(category => {
              if (category?.footnote) {
                return {
                  ...category,
                  text: null,
                  jsx: (
                    <Text
                      lineHeight={LINE_HEIGHT.TIGHT.SMALL}
                      align="center"
                      className="px-3 m-0"
                    >
                      {category?.sys?.id
                        ? ReactHTMLParser(category?.text)
                        : category?.text}
                      <sup>
                        <Scroll.Link
                          className="link--sup"
                          to={category?.footnote?.target}
                          spy
                          smooth
                          duration={500}
                          offset={-200}
                        >
                          {category?.footnote?.label}
                        </Scroll.Link>
                      </sup>
                    </Text>
                  )
                };
              } else {
                return category;
              }
            })}
          />
          <HowToApply
            id="how-to-apply"
            box1={<HowToApplyList variant="sym" />}
            box2={<PLEligibilityCriteria />}
          />

          <Box.Section
            backgroundColor={COLOR.GREY5}
            paddingTop={MARGIN.M32}
            paddingBottom={MARGIN.M32}
          >
            <AnalyticsLocationProvider location="Recommended reads">
              <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Recommended reads
              </Heading4>
              <CardGroup>
                {pageQuery.allMarkdownRemark.edges.map(item => (
                  <CardGroupItem key={item.node.frontmatter.path}>
                    <ArticleTile isBranded {...item.node.frontmatter} />
                  </CardGroupItem>
                ))}
              </CardGroup>
            </AnalyticsLocationProvider>
          </Box.Section>
          <Faq data={PageData.content.faq} />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            {...state?.importantInfoData?.[0]}
          />
        </Box>
      </main>
    </Layout>
  );
};

export default DebtConsolidationLoanPage;
