import { marked } from "marked";
import { Link as LinkScroll } from 'react-scroll';

const renderer = new marked.Renderer();
renderer.paragraph = function(text) {
  return text;
}

marked.setOptions({renderer:renderer})

export default marked;